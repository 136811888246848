.attOper{
    float:left;
    margin-bottom:10px;
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .attOper{
        order:1;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .attOperBtn{
        padding-top: 14px;
    }
    .attHeader{
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .attPer{
    order: 2;
    margin-bottom: 15px;
    }
    .attOperRange{
       
        margin-top: 15px;
    }
    .disPer{
        order: 3; 
    }
    }