.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
.discountRange{
    margin-left: 10px;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: row;
    }
    .discountRange{
        order: 2;
        margin-top: 10px;
    }
    .discountBtn{
        justify-content: flex-end;
        }
}